<template>

  <div>
    <div class="roster__pdf kitchen-view__table">
      <b-table-simple
        ref="teamsTable"
        class="position-relative has_padding"
        responsive
        hover
        caption-top
        style="width: 100%; page-break-after: auto; page-break-inside: avoid; border-collapse:separate; border-spacing: 10px 5px;"
      >
        <b-thead>
          <b-tr>
            <b-th
              colspan="5"
            />
            <b-th
              :colspan="kitchenItems.length"
              class="text-center"
              style="background-color: #B3C2C2 !important"
            >
              Offerings
            </b-th>
            <b-th
              colspan="3"
            />
          </b-tr>
          <!-- <b-tr>
            <b-th
              class="width-5p"
              rowspan="4"
            >
              ID
            </b-th>
            <b-th rowspan="4">
              Request Details
            </b-th>
            <b-th
              class="width-5p"
              rowspan="4"
            >
              Service Date
            </b-th>
            <b-th
              class="width-5p"
              rowspan="4"
            >
              Service Time
            </b-th>
            <b-th rowspan="4">
              Service Venue
            </b-th>
            <b-th
              v-for="(kitchenItem, kitchenItemindex) in kitchenItems"
              :key="kitchenItemindex"
              class="rotate-column"
              rowspan="4"
              style="width: 5%;
                    height: 10%;
                    rotate: -90deg;"
            >
              <span>{{ kitchenItem.key }}</span>
            </b-th>
            <b-th rowspan="4">
              Customer
            </b-th>
            <b-th rowspan="4">
              Assigned To
            </b-th>
            <b-th rowspan="4">
              Remarks
            </b-th>
          </b-tr> -->
          <b-tr>
            <b-th
              class="width-5p"
            >
              ID
            </b-th>
            <b-th class="width-15p">
              Request Details
            </b-th>
            <b-th
              class="width-7p"
            >
              Service Date
            </b-th>
            <b-th
              class="width-7p"
            >
              Service Time
            </b-th>
            <b-th>
              Service Venue
            </b-th>
            <b-th
              v-for="(kitchenItem, kitchenItemindex) in kitchenItems"
              :key="kitchenItemindex"
              class="width-5p"
              style="white-space: normal !important;"
            >
              <span
                style="transform: rotate(-90deg); display: inline-block;
                min-width: 80px;
                min-height: 80px;
                max-width: 100px;
                max-height: 80px;
                white-space: normal !important;
              "
              >{{ kitchenItem.key }}</span>
            </b-th>
            <b-th>
              Customer
            </b-th>
            <b-th>
              Assigned To
            </b-th>
            <b-th>
              Remarks
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(serviceRequest, index) in serviceRequests"
            :key="index"
            style="page-break-after: auto; page-break-inside: avoid;"
          >
            <b-td
              class="width-5p"
              style="letter-spacing: 0.5px;"
            >
              {{ serviceRequest.stringID }}
              <br>
              fr. {{ serviceRequest.serviceFormStringID }}
            </b-td>
            <b-td
              class="width-15p"
              style="letter-spacing: 0.5px; white-space: normal !important;"
            >
              {{ serviceRequest.workFlowName }}
              <br>
              File No: {{ serviceRequest.fileNo || '-' }}
            </b-td>
            <b-td
              class="width-7p"
              style="letter-spacing: 0.5px;"
            >
              {{ dateFormat(serviceRequest.date) }}
            </b-td>
            <b-td
              class="width-7p"
              style="letter-spacing: 0.5px;"
            >
              {{ timeFormatFromDateString(serviceRequest.date) }}
            </b-td>
            <b-td style="letter-spacing: 0.5px; white-space: normal !important;">
              {{ getLocationFromDescription(serviceRequest.serviceDescription) }}
            </b-td>
            <b-td
              v-for="(kitchenItem, itemIndex) in kitchenItems"
              :key="itemIndex"
              class="width-5p text-center"
            >
              <div class="text-center">
                <span>{{ resolveKitchenItems(serviceRequest.serviceDescription, kitchenItem.id) }}</span>
              </div>
            </b-td>
            <b-td style="letter-spacing: 0.5px; white-space: normal !important;">
              {{ serviceRequest.customerName }}
              <br>
              <span
                v-if="serviceRequest.customerContact"
              >
                {{ serviceRequest.customerContact }}
              </span>
            </b-td>
            <b-td style="letter-spacing: 0.5px; white-space: normal !important;">
              <div
                v-if="serviceRequest.assignees.length"
                class=""
              >
                <span
                  v-for="(user, assigneeindex) in serviceRequest.assignees"
                  :key="assigneeindex"
                >
                  {{ user.name }}<span v-if="assigneeindex < (serviceRequest.assignees.length - 1)">, </span>
                </span>
              </div>
              <div v-else>
                -
              </div>
            </b-td>
            <b-td style="letter-spacing: 0.5px; white-space: normal !important;">
              {{ serviceRequest.remarks }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td
              style="background: #D9E0E0 !important; border-bottom: 1px solid #B3C2C2;"
              colspan="5"
            >
              <b style="font-weight: 700;">Total Items to be prepared</b>
            </b-td>
            <b-td
              v-for="(kitchenItem, itemIndex) in kitchenItems"
              :key="itemIndex"
              class="text-center"
              style="background: #D9E0E0 !important; border-bottom: 1px solid #B3C2C2;"
            >
              <div class="text-center">
                <span>{{ totalKitchenItems(kitchenItem.id) }}</span>
              </div>
            </b-td>
            <b-td
              style="background: #D9E0E0 !important; border-bottom: 1px solid #B3C2C2;"
              colspan="3"
            />
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <!-- modal -->
  </div>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import 'array.prototype.move'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      serviceRequests: [],
      kitchenItems: [],
      venueFieldName: '',
    }
  },
  mounted() {
    const {
      search, sortBy, sortDesc, venue, offering, dueDate, serviceDate, requestType, assignee, type, status,
    } = this.$route.query
    this.$http.post('download/service-requests/kitchen-view/show', {
      search,
      sortBy,
      sortDesc,
      venue,
      offering,
      dueDate,
      serviceDate,
      requestType,
      assignee,
      type,
      status,
    })
      .then(response => {
        this.venueFieldName = response.data.venueField
        this.serviceRequests = response.data.serviceRequests ?? []
        this.kitchenItems = response.data.kitchenItems ?? []
      })
  },
  methods: {
    getLocationFromDescription(data) {
      if (data && data.length) {
        const venue = data.find(o => o.key === this.venueFieldName)
        if (venue && Array.isArray(venue.value) && venue.value.length) {
          return venue.value[0]
        }
      }
      return ''
    },
    resolveKitchenItems(serviceDescription, id) {
      let counter = 0
      serviceDescription.forEach(arrayItem => {
        if (id === arrayItem.id) {
          counter = 0
          if (arrayItem.type === 'file') {
            if (arrayItem.value) {
              /* eslint-disable-next-line no-plusplus */
              counter++
            }
          } else if (arrayItem.type === 'title-and-text') {
            if (arrayItem.value) {
              /* eslint-disable-next-line no-plusplus */
              counter++
            }
          } else if (arrayItem.type === 'short-answer') {
            if (arrayItem.value) {
              /* eslint-disable-next-line no-plusplus */
              counter++
            }
          } else if (arrayItem.type === 'long-answer') {
            if (arrayItem.value) {
              /* eslint-disable-next-line no-plusplus */
              counter++
            }
          } else if (arrayItem.type === 'multi-select') {
            if (arrayItem.value.length) {
              /* eslint-disable-next-line no-plusplus */
              counter += arrayItem.value.length
            }
          } else if (arrayItem.type === 'single-select') {
            if (arrayItem.value) {
              /* eslint-disable-next-line no-plusplus */
              counter++
            }
          } else if (arrayItem.value) {
            /* eslint-disable-next-line no-plusplus */
            counter += arrayItem.value
          }
        }
      })
      if (counter) {
        return counter
      }

      return '-'
    },
    totalKitchenItems(kvId) {
      let counter = 0
      this.serviceRequests.forEach(arrayItem => {
        arrayItem.serviceDescription.forEach(description => {
          if (kvId === description.id) {
            if (description.type === 'file') {
              if (description.value) {
                /* eslint-disable-next-line no-plusplus */
                counter++
              }
            } else if (description.type === 'title-and-text') {
              if (description.value) {
                /* eslint-disable-next-line no-plusplus */
                counter++
              }
            } else if (description.type === 'short-answer') {
              if (description.value) {
                /* eslint-disable-next-line no-plusplus */
                counter++
              }
            } else if (description.type === 'long-answer') {
              if (description.value) {
                /* eslint-disable-next-line no-plusplus */
                counter++
              }
            } else if (description.type === 'multi-select') {
              if (description.value.length) {
                /* eslint-disable-next-line no-plusplus */
                counter += description.value.length
              }
            } else if (description.type === 'single-select') {
              if (description.value) {
                /* eslint-disable-next-line no-plusplus */
                counter++
              }
            } else if (description.value) {
              /* eslint-disable-next-line no-plusplus */
              counter += description.value
            }
          }
        })
      })

      return counter
    },
  },
}
</script>

<style scoped>
    table td.no-padding-td {
      padding: 12px 0px !important
    }
    table td.td-no-padding-right {
      padding: 12px 8px !important
    }
    .white-body-background {
      background-color: white;
    }
    .v-select {
      width: 95px;
    }
    .b-table-sticky-header > .table.b-table > thead > tr > th {
        position: sticky;
        top: 38px;
        z-index: 5;
    }
    .b-table-sticky-header > .table.b-table > thead > tr:last-child > th{
      position: sticky;
        top: 103px;
        z-index: 5;
    }
    .roster__grouping.monthly__grouping .table.b-table.b-table-caption-top > caption {
        position: sticky;
        top: 0;
        z-index: 5;
    }
    .table-responsive > .table.b-table > thead > tr > .b-table-sticky-column{
      left:0;
      z-index: 6!important;
    }
    .roster__grouping.monthly__grouping .no-padding-td .table-responsive > table > tbody > tr > td.b-table-sticky-column{
      left:0;
      z-index: 3!important;
      position: sticky;
    }
    .width-3p{
        width: 3% !important;
    }
    .width-4p{
        width: 4% !important;
    }
    .width-5p{
        width: 5% !important;
    }
    .width-7p{
        width: 7% !important;
    }
    .width-10p{
        width: 10% !important;
    }
    .width-15p{
        width: 10% !important;
    }
    .roster__pdf.kitchen-view__table .table-responsive > table > thead > tr > th{
      background-color: #D9E0E0 !important;
      border-top: 1px solid #B3C2C2 !important;
      border-bottom: 1px solid #B3C2C2 !important;
      color: #000 !important;
      font-size: 8px !important;
    }
    .roster__pdf.kitchen-view__table .table-responsive > table > tbody > tr > td{
      border-top: 1px solid #B3C2C2;
      color: #000 !important;
      font-size: 8px !important;
      line-height: 10px !important;
    }
</style>
<style lang="scss">
    body div#app.semi-dark-layout {
        background: #fff !important;
        background-repeat: no-repeat !important;
        background-position: center bottom !important;
        background-size: 100% auto !important;
    }
    body {
        background: #fff !important;
    }
    .card {
        box-shadow: none !important;
    }
    .rotate-column{
    //  rotate: -90deg;
    width: 3.5% !important;
    height: 20% !important;
    writing-mode: vertical-lr;
    }
</style>
<style>
    @media print {
        html, body {
            height: 99%;
        }
        @page {
          size: A4 portrait;
        }
    }
</style>
